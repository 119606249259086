import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TermsService = {

  /**

   *
   * @param type
   * @returns {Promise<any>}
   */
  getTerm (type) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/termos/${type}`, {}).then(function (res) {
          resolve({ data: res.data })
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default TermsService
